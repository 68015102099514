const isMobileSEB = () => {
  const userAgent = navigator.userAgent;
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent) || (userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
  const isSEB = userAgent.match(/SEB/);

  return isIOS && isSEB;
}

const setContainerHeight = () => {
  if (isMobileSEB()) {
    const fullHeight = document.documentElement.clientHeight;

    document.body.style.minHeight = `${fullHeight}px`;
    const mainContainer = document.querySelector('#main-container');

    if (mainContainer) {
      mainContainer.style.height = `${fullHeight}px`;
    }
  }
}

document.addEventListener('DOMContentLoaded', setContainerHeight);
window.addEventListener('resize', setContainerHeight);