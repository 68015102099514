import Rails from "@rails/ujs";
import 'bootstrap';

import '../scripts/msc_filters';
import '../scripts/session_timeout';
import '../scripts/table_checkbox';
import '../scripts/goto_section';
import '../scripts/typeahead';

import '../scripts/select2_init';
import '../scripts/tooltip_init';
import '../scripts/modal';
import '../scripts/modal_lightbox';
import '../scripts/auto_update';

import '../scripts/seb_viewport_fix';

import '../pdfs/akt_privacy_notice.pdf'
import '../pdfs/mscaa_privacy_notice.pdf'

import { AjaxModal, ConfirmModal, FlashMessage, VisibilityMap, TableReflow } from '@epigenesys/epi-js';

window.FlashMessage = FlashMessage;

AjaxModal.options.errorModalDismissButtonClass = 'btn btn-default';
ConfirmModal.options.cancelButtonClass = 'btn btn-default';

AjaxModal.start();
VisibilityMap.start();
TableReflow.start();

Rails.confirm = function (message, element) {
  return ConfirmModal.confirm(message, element);
}

Rails.start();
